.splash_screen_c {
    position: fixed;
    z-index: 77;
    top: 0px;
    /* top: 50%; */
	/* transform : translate ( 0, 50% ); */

    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: #fefefe;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
}

.splash_screen_c > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.start_button1 {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    
    width: calc(100vw - 40px);
    height: 48px;
    
    /* Main/Pink */
    background: #FF7A7A;
    border-radius: 100px;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.2px;
    
    /* Main/White */
    color: #FFFFFF;
    
    
    position: fixed;
    bottom : 100px;
    left: 20px;
    z-index: 999;
    
    }

    .start_button2 {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        
        width: calc(100vw - 40px);
        height: 48px;
        
        /* Main/Pink */
        background: #ffffff;
        border-radius: 100px;
        
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        /* identical to box height, or 16px */
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.2px;
        
        /* Main/White */
        color: #1a1a1a;
        
        
        position: fixed;
        bottom : 40px;
        left: 20px;
        z-index: 999;
        
        }
            

.splash_gif {
    width: calc(100vw - 40px);
}
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard.css");
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

* {
  box-sizing: border-box;
  /* overflow-x : hidden; */

 font-family: 'NanumSquare';
}


.top_image_row0 {

  /* width: 100px; */
  height: 40px;
  right: 20px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  /* identical to box height, or 208% */
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  
  color: #FF7A7A;
  z-index: 9;
  
}

.top_image_row0 img {
  width : 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 4px;
  margin-right: 4px;
}

html,
body {
  height: 100%;
  font-family: "Pretendard Std", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;


}
#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
 
/* ::-webkit-scrollbar {
  width: 4px;
} 
::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
::-webkit-scrollbar-thumb {
  background: #181818; 
  border-radius : 20px;
} 
::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}


.__main {
  margin: auto;
  background-color: #F8F8F8;

  width: 100vw;
  height: 100vh;
  min-height: 1335px;
  padding: 20px;
  display: flex;
  padding: 0;

  z-index: 0;
  position: relative;
  overflow-y: scroll;

  max-width : 400px;
  overflow : hidden;

}



.textinput_form_container0 {
  width: calc(100vw - 40px);
  height: 50px;
  min-height: 50px;
  background: #F8F8F8;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;

  position: relative;
}




.textinput_form_container0 > input {
  width: calc(100% - 70px);
  height: 100%;

  display: flex;
  margin-left: 20px;
  margin-right: auto;
}
 .textinput_form_container0 > img {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: contain;

}

.color_select_container0 {
  width: calc(100% - 40px);
  min-height: 100px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;


  
}
.color_select_container0 > .colordiv0 {

  width: 42.5px;
  height: 42.5px;
  border-radius: 21.25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .color_select_container0 > .colordiv0 {
  margin-left: 20px;
}
.color_select_container0 > .colordiv0:nth-child(1) {
  margin-left: 0px;
}
.color_select_container0 > .colordiv0:nth-child(7) {
  margin-left: 0px;
} */
.color_select_container0 > .colordiv0 > .inner0 {

width: 32.5px;
height: 32.5px;
border-radius: 16.25px;
display: flex;
align-items: center;
justify-content: center;
}
.color_select_container0 > .colordiv0 > .inner0 > img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.textinput_form_check_row0 {
  width: calc(100vw - 40px);
  margin-left: auto;
  margin-right: auto;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;

}
.textinput_form_check_row0   img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.textinput_form_check_row0  .col50 {
  width: calc(50% - 20px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.textinput_form_check_row0  .col100 {
  width: calc(100% - 40px);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;
}
.textinput_form_check_row0   .text1 {


font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #212529;
margin-left: 12px;
}
.textinput_form_check_row0   .vl {

width: 1px;
height: 8px;
left: calc(50% - 0.5px);
top: calc(50% - 4px - 31px);

background: rgba(33, 37, 41, 0.2);

margin-left: auto;
margin-right: auto;
}
.textinput_form_check_row0   .text2 {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  /* identical to box height, or 179% */
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.5px;
  
  color: #212529;
  
}


.submit_button0 {
  width: calc(100vw - 40px);
  height: 50px;
  min-height: 50px;
  
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  
  margin-top: 10px;

}



.input_label0 {

  width: calc(100% - 40px);
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  /* identical to box height, or 179% */
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  
  color: #212529;

  margin-bottom: 12px;
  
}
.input_label0 .col50 {
  width: 50%;
  display: flex;
  align-items: center;
}
.textinput_form_container_search0 {

  width: calc(100% - 70px);
  height: 100%;
 
  display: flex;
  margin-left: 20px;
  margin-right: auto;
}
.textinput_form_container_phone0  input {
  width: calc(100% - 70px);
  height: 100%;
 
  display: flex;
  margin-left: 20px;
  margin-right: auto;
 }
.textinput_form_container_phone0  img {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: contain;

}

.textinput_form_container_phone0 {
  width: calc(100vw - 40px);
  height: 50px;
  background: none;
  margin-left: auto;
  margin-right: auto;
 
  position: relative;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

 }

 .textinput_form_container_phone0 .input_wrapper0 {
  width: calc(100vw - 200px);
  height: 50px;
  background: #F8F8F8;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;

  position: relative;

 }

.textinput_form_container_phone0 .button0 {

width: 140px;
height: 50px;

background: #FFFFFF;
border: 1px solid rgba(33, 37, 41, 0.2);
border-radius: 30px;

margin-left: auto;



font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.5px;

color: #212529;

display: flex;
align-items: center;
justify-content: center;


}

.textinput_form_container_phone0 {
  width: calc(100vw - 40px);
  height: 50px;
  background: none;
  margin-left: auto;
  margin-right: auto;
 
  position: relative;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

 }

 .textinput_form_container_phone0 .input_wrapper50 {
  width: calc(50% - 10px);
  height: 50px;
  background: #F8F8F8;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;

  position: relative;

 }


 .global_popup {
  width : calc(100vw - 32px);
  height: auto;
  min-height: 50px;
  position : fixed;
  top: 16px;
  left: 16px;

  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px;
  color : #FF7A7A;
  font-weight: 700;
  font-size: 16px;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
 }




.arrow_container {
  width: calc(100vw - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  margin-top: 20px;
}
.arrow_container img {
  width: 8px;
  height: 8px;
  object-fit : contain;
}
.arrow_container .arrow_leftdiv0 {
  display: flex;
  align-items: center;
  justify-content: center;
  width : calc(50% - 4px);
  height: 50px;
  background: #FFFFFF;
  border: 1px solid rgba(33, 37, 41, 0.2);
  border-radius: 30px;
}
.arrow_container .arrow_rightdiv0 {
  
  display: flex;
  align-items: center;
  justify-content: center;
  width : calc(50% - 4px);
  height: 50px;
  color : #fff;
  background: #181818;
  border-radius: 30px;
  margin-left: 8px;
}
.arrow_container .arrow_leftdiv0 img {
  margin-left: 20px;
  margin-right: auto;
}

.arrow_container .arrow_leftdiv0 .text1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;


font-weight: 700;
font-size: 15px;
line-height: 25px;
/* identical to box height, or 167% */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.5px;

color: #212529;

}

.arrow_container .arrow_rightdiv0 img {
  margin-left: auto;
  margin-right: 20px;
}
.arrow_container .arrow_rightdiv0 .text1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 25px;
/* identical to box height, or 167% */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.5px;

color: #FFFFFF;

}



.ql-editor {
  color : #000000;
  font-size: 18px;
}

.card__list {
  max-width: 400px;
  overflow: scroll;
}



.fab_001 {

  position: fixed;
  z-index: 77;
width: 120px;
height: 40px;
right: 20px;
bottom: 100px;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

background: #FF7A7A;
box-shadow: 0px 6px 10px rgba(104, 65, 1, 0.08);
border-radius: 20px;

font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
letter-spacing: -0.5px;

color: #FFFFFF;


 }

 .fab_001 img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 8px;
 }

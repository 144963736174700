
  .swipe {
    position: absolute;
  }
  
  .cardContainer {
    width: 100%;
    max-width: 320px;
    height: 537px;
    
    /* Pink/03 */
    /* background: #FFC6C6; */
    border-radius: 16px;

    margin-left: auto;
    margin-right: auto;

    margin-top : 40px;
  }

  .cardFirst {

    width: 100%;
    max-width: 320px;
    height: 537px;
    
    /* Pink/03 */
    /* background: #FFC6C6; */
    border-radius: 16px;

    margin-left: auto;
    margin-right: auto;

    margin-top : 40px;
  }
  .cardFirst img {
    width : 100%;
    height: 100%;
  }
  
  .card {
    position: relative;
    background-color: #fff;
    width: 80vw;
    max-width: 320px;
    height: 537px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.10);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .cardContent {
    width: 100%;
    height: 100%;
  }
  
  .swipe:last-of-type {
  
  }
  
  .card .bottomInfoDiv1 {
    position: absolute;
    bottom: 0;

    margin-left: 18px;
    margin-right : 18px;
    color: #fff;
  }
  
  .infoText {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  .buttons {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  @media (max-width: 625px) {
    .buttons {
      flex-direction: column;
    }
  }
  
  .buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 10px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
  }
  
  .buttons button:hover {
    transform: scale(1.05);
  }
  
  @keyframes popup {
    0%   { transform: scale(1,1) }
    10%  { transform: scale(1.1,1.1) }
    30%  { transform: scale(.9,.9) }
    50%  { transform: scale(1,1) }
    57%  { transform: scale(1,1) }
    64%  { transform: scale(1,1) }
    100% { transform: scale(1,1) }
  }
  
.tap01_c > .top_remind_folder_wrapper0 {

width: 100vw;
height: calc(100vw * 230 / 375);
/* width: 375px; */
min-height: 230px;
left: 0px;
top: -1px;

background: #FFFFFF;
/* border-radius: 10px 10px 0px 0px; */
border-radius: 10px;

display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;

position: relative;

}

.tap01_c > .top_remind_folder_wrapper0 > .row1 {
width: 100vw;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
}
.tap01_c > .top_remind_folder_wrapper0 > .row1 img {
width: 16px;
height: 16px;
object-fit: contain;
margin-left: 20px;
margin-right: 20px;
}

.tap01_c > .top_remind_folder_wrapper0 > .row1 .more1 {
width: 90px;
height: 30px;

background: #FFFFFF;
border: 1px solid rgba(33, 37, 41, 0.2);
border-radius: 15px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 30px;
/* identical to box height, or 214% */
letter-spacing: -0.05em;

color: #212529;

margin-left: auto;
margin-right: 20px;
}
.tap01_c > .top_remind_folder_wrapper0 > .row1 .more1  .text1 {

margin-left: 8px;
font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 30px;
/* identical to box height, or 214% */
letter-spacing: -0.05em;

color: #212529;

}

.tap01_c > .top_remind_folder_wrapper0 > .row1 .more1 > img {

width: 8px;
height: 8px;
object-fit: contain;

margin-left: auto;
}

.tap01_c > .top_remind_folder_wrapper0 > .row1 .text1 {
font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 30px;
/* identical to box height, or 167% */
letter-spacing: -0.05em;

color: #181818;

}


.foldercard0 {

    width: calc(50vw - 32px);
    min-width: calc(50vw - 32px);
    max-width: calc(50vw - 32px);
    height: 140px;
    min-height: 140px;
    max-height: 140px;
    
    background: #D2BCEE;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    position: relative;
    margin-left: 20px;
    margin-top: 20px;
    }
    .listcard0 {
    
    width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
    max-width: calc(100vw - 32px);
    height: 140px;
    min-height: 140px;
    max-height: 140px;
    
    background: #D2BCEE;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    position: relative;
    margin-left: 20px;
    margin-top: 20px;
    }
.foldercard0 .checkicon0, 
.listcard0 .checkicon0
 {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-left: auto;
}
.foldercard0 > .punchhole0,
.listcard0 > .punchhole0
 {

width: 20px;
height: 20px;
min-width: 20px;
min-height: 20px;
max-width: 20px;
max-height: 20px;
border-radius: 10px;
top: 0px;
left: 50%;
transform: translate(-50%, -10px);

background: #FFFFFF;
z-index: 67;
}



.row0 {

display: flex;
flex-direction: row;
align-items: center;
}
.cardrow0 {
width: 100vw;
overflow-x: scroll;
}

.foldercard0 > div , 
.listcard0 > div 
{
width: calc(100% - 30px);
margin-left: auto;
margin-right: auto;
}
.foldercard0 > .row1,
.listcard0 > .row1
 {
margin-top: 12px;
}
.foldercard0 .lock > img, 
.listcard0 .lock > img
 {
width: 15px;
height: 15px;
object-fit: contain;
}

.foldercard0 .text1, 
.listcard0 .text1
 {



font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
letter-spacing: -0.05em;

color: #212529;

}
.foldercard0 .text2, 
.listcard0 .text2
 {

font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */
letter-spacing: -0.05em;

color: #868E96;


}

.foldercard0 .text3, 
.listcard0 .text3
 {

display: flex;


font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 25px;
/* identical to box height, or 208% */
letter-spacing: -0.05em;

color: #ADB5BD;

margin-top: auto;
margin-bottom: 12px;

}


.foldercard0 .text3_1, 
.listcard0 .text3_1
 {


font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */
letter-spacing: -0.05em;

color: rgba(33, 37, 41, 0.7);

}

.foldercard0 .text3_2, 
.listcard0 .text3_2
 {

font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
text-align: right;
letter-spacing: -0.05em;

color: #212529;

margin-left: auto;

}


.foldercard0 .text3_3,
.listcard0 .text3_3
 {

font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */
text-align: right;
letter-spacing: -0.05em;

color: #212529;

}


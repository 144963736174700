 .top_recent_card_wrapper0 {

width: 100vw;
padding-bottom: 20px;
background: #F8F8F8;

display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;

position: relative;

}
 .top_recent_card_wrapper0 > .row1 {
width: 100vw;
margin-left: auto;
margin-right: auto;
}
 .top_recent_card_wrapper0 > .row1 img {
width: 16px;
height: 16px;
object-fit: contain;
margin-left: 20px;
margin-right: 20px;
}

 .top_recent_card_wrapper0 > .row1 .more1 {
width: 90px;
height: 30px;

background: #FFFFFF;
border: 1px solid rgba(33, 37, 41, 0.2);
border-radius: 15px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 30px;
/* identical to box height, or 214% */
letter-spacing: -0.05em;

color: #212529;

margin-left: auto;
margin-right: 20px;
}
 .top_recent_card_wrapper0 > .row1 .more1  .text1 {

margin-left: 8px;
font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 30px;
/* identical to box height, or 214% */
letter-spacing: -0.05em;

color: #212529;

}

 .top_recent_card_wrapper0 > .row1 .more1 > img {

width: 8px;
height: 8px;
object-fit: contain;

margin-left: auto;
}

 .top_recent_card_wrapper0 > .row1 .text1 {
font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 30px;
/* identical to box height, or 167% */
letter-spacing: -0.05em;

color: #181818;

}


.card0 {

width: 105px;
height: 140px;
min-width: 105px;
min-height: 140px;
max-width: 105px;
max-height: 140px;

background: #FFFFFF;
border-radius: 10px;

display: flex;
flex-direction: column;
align-items:flex-start;
justify-content: flex-start;

position: relative;

margin-left: 20px;
margin-top: 20px;
}

.row0 {

display: flex;
flex-direction: row;
align-items: center;
}
.cardrow0 {
width: 100vw;
overflow-x: scroll;
}

.card0 > div {
width: calc(100% - 30px);
margin-left: auto;
margin-right: auto;
}
.card0 > .row1 {
margin-top: 12px;
}
.card0 .tag0 {

width: 10px;
height: 10px;
border-radius: 5px;

background: #D2BCEE;
margin-right: auto;
}
.card0 .star > img {
width: 15px;
height: 15px;
object-fit: contain;
}

.card0 .text1 {


font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
letter-spacing: -0.05em;

color: #212529;

}
.card0 .text2 {

font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */
letter-spacing: -0.05em;

color: #868E96;


}

.card0 .text3 {


font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 25px;
/* identical to box height, or 208% */
letter-spacing: -0.05em;

color: #ADB5BD;

margin-top: auto;
margin-bottom: 12px;

}


.card_around1 {

    width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
    max-width: calc(100vw - 32px);
    height: 140px;
    min-height: 140px;
    max-height: 140px;
    
    background: #FFFFFF;
    border-radius: 10px;
    
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    
    position: relative;
    
    margin-left: 16px;
    margin-top: 16px;
    border: 1px solid #FF7A7A;
    overflow: hidden;
}


.card1 {

    width: calc(50vw - 32px);
    min-width: calc(50vw - 32px);
    max-width: calc(50vw - 32px);
    height: 140px;
    min-height: 140px;
    max-height: 140px;
    
    background: #FFFFFF;
    border-radius: 10px;
    
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    
    position: relative;
    
    margin-left: 20px;
    margin-top: 20px;
    overflow: hidden;
    }
    
    .row0 {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    }
    .cardrow0 {
    width: 100vw;
    overflow-x: scroll;
    }
    
    .card1 > div {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    }
    .card1 > .row1 {
    margin-top: 12px;
    }
    .card1 .tag0 {
    
    width: 10px;
    height: 10px;
    border-radius: 5px;
    
    background: #D2BCEE;
    margin-right: auto;
    }
    .card1 .star > img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    }
    
    .card1 .text1 {
    
    
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    letter-spacing: -0.05em;
    
    color: #212529;
    
    }
    .card1 .text2 {
    
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    letter-spacing: -0.05em;
    
    color: #868E96;
    
    
    }
    
    .card1 .text3 {
    
    
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    /* identical to box height, or 208% */
    letter-spacing: -0.05em;
    
    color: #ADB5BD;
    
    margin-top: auto;
    margin-bottom: 12px;
    
    }
    


.profTopNavWrapper {
    
    width : 100vw;
    /* width : calc(100vw - 40px); */
    min-height: 52px;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .profTopNavTopBar {
      
    width : 100vw;
    height: 52px;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  
    position : relative;
  }

  
  .profTopNavDiv1 {
    
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 100%;
/* identical to box height, or 13px */
display: flex;
align-items: center;
text-align: center;

/* shade/gray80 */
color: #343A3F;

    margin-left: auto;
  }
  
  .profTopIcon1 {

width: 24px;
height: 24px;
object-fit: contain;
margin-left: 12px;
margin-right: 12px;

  }
  

.profTopPrevWrapper {

/* Auto layout */
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

width: 100%;
height: 312px;

border-bottom : 20px solid #f2f2f2;

}
.profImgWrapper {

width: 74px;
height: 74px;
border-radius: 37px;
overflow: hidden;

margin-left: auto;
margin-right: auto;

display: flex;
align-items: center;
justify-content: center;

}
.profImg {

    width : 110%;
    height: 110%;
    object-fit: cover;


}
.proNicknameWrapper {

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

margin-top: 10px;

}

.proNicknameWrapper .text1 {

font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 100%;
/* identical to box height, or 18px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.002em;

/* Main/Black */
color: #0F0F0F;

}

.proNicknameWrapper .icon1 {


width: 18px;
height: 18px;
object-fit: contain;

margin-left: 4px;

}



.profPrevStatusWrapper {

    width : calc(100% - 40px);
    
/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

margin-left: auto;
margin-right: auto;

margin-top: 20px;
margin-bottom: 20px;


}
.profPrevStatusWrapper .tab0 {
    width : calc(50% - 0.5px);
    height: 54px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-left: auto;
    margin-right: auto;
}

.profPrevStatusWrapper .tab1 {

}

.profPrevStatusWrapper .tabDiv1 {
    width : 1px;
    height : 30px;
    background-color: #F2F2F2;
}
.profPrevStatusWrapper .tab0 .icon1 {
    width: 30px;
    height: 30px;

    object-fit: contain;

}
.profPrevStatusWrapper .tab0 .col1 {

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-left: 10px;

}
.profPrevStatusWrapper .tab0 .col0 .text1 {
    
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.002em;

/* Gray/09 */
color: #2D2D2D;

}

.profPrevStatusWrapper .tab0 .col0 .text2 {
    
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 100%;
/* identical to box height, or 18px */
display: flex;
align-items: center;
text-align: center;

/* Gray/09 */
color: #2D2D2D;


margin-top : 8px;

}

.checkin-button1 {


width: calc(100% - 40px);
height: 46px;

/* Main/Pink */
background: #FF7A7A;
border-radius: 100px;

margin-left: auto;
margin-right: auto;

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;


font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.2px;

/* Main/White */
color: #FFFFFF;


}



.profTopTabContentWrapper {
  width : 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.profTopTabContentWrapper .sectionLabel0 {


  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  display: flex;
  align-items: center;
  text-align: flex-start;
  letter-spacing: -0.002em;
  
  /* Main/Black */
  color: #0F0F0F;

  width : calc(100vw - 40px);
  margin-left: 20px;
  margin-right : auto;

  margin-top: 48px;
  
}
.profTopTabContentWrapper .sectionLabel0:nth-child(1) {

  margin-top: 60px;
  
}
.profTopTabContentWrapper .sectionGrayWrapper0 {


  width : calc(100vw - 40px);
  margin-left: 20px;
  margin-right : auto;
  margin-top: 18px;


  display: flex;
  flex-direction: column;
  align-items: flex-start;

display: flex;
flex-direction: column;
align-items: flex-start;

/* height: 106px; */

/* Gray/00 */
background: #F2F2F2;
border-radius: 16px;

margin-top: 18px;


}

.sectionGrayWrapper1 .row0 {
  width : calc(100% - 40px);

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.sectionGrayWrapper1 .row0 .icon1 {
  width: 34px;
  height: 34px;
  object-fit: contain;
  margin-left: 20px;
  margin-right : 12px;
}
.sectionGrayWrapper1 .row0 .text1 {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  display: flex;
  align-items: center;
  text-align: center;
  
  /* Gray/08 */
  color: #424242;
  
}
.sectionGrayWrapper1 .row0 .textEmpty {

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */
  display: flex;
  align-items: center;
  text-align: center;
  
  /* Gray/06 */
  color: #8B8B8B;
  
}

.sectionGrayWrapper1 .row0 .icon2 {
  width: 34px;
  height: 34px;
  object-fit: contain;
  margin-left: auto;
}

.sectionGrayWrapper1 .row0 {

height: 69px;
display: flex;
align-items: center;
margin-top: auto;
margin-bottom : auto;
}

.tagggItemWhite {

box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 10px 8px 12px;
gap: 10px;

min-width: 77px;
height: 32px;

/* Main/White */
background: #FFFFFF;
/* Gray/03 */
border: 1px solid #E2E2E2;
border-radius: 30px;

font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */
text-align: center;
letter-spacing: -0.02em;
text-transform: capitalize;

/* Gray/09 */
color: #2D2D2D;

margin-bottom : 10px;

white-space: nowrap;

}

.tagggItemWhite:nth-child(3n - 2) {
  margin-right: 8px;
 }
 .tagggItemWhite:nth-child(3n - 1) {
  margin-right: 8px;
 }
 .tagggItemWhite:nth-child(3n) {
  margin-right: 12px;
 }




.sectionGrayWrapper2 {
  
  min-height: 106px;
}

.sectionGrayWrapper2 .emptyIconOnly {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
}
.sectionGrayWrapper2 .row0 {

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  margin-top: 16px;
  width : calc(100% - 20px);
  margin-left: 10px;
}

.sectionGrayWrapper3 {

/* Gray/00 */
background: #F9F9F9;
border-radius: 16px;
}
.sectionGrayWrapper3 .row0 {

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */
  
  /* Gray/08 */
  color: #424242;
  
  margin-top: 16px;
  margin-bottom : 16px;
  width : calc(100% - 20px);
  margin-left: 10px;
}

.infoRow1 {

  width : 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.infoRow1 .text1 {
  
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.002em;

/* Main/Black */
color: #0F0F0F;
margin-left: 20px;
}
.infoRow1 .text2 {
  
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */

/* Gray/07 */
color: #666666;

margin-left: auto;
}
.infoRow1 .icon1 {
  

width: 24px;
height: 24px;
object-fit: contain;
margin-left: 6px;
margin-right : 20px;
}

.infoRow1:nth-child(1) {
  margin-top: 30px;
}





.infoRow2 {

  width : calc(100vw - 40px);
/* Auto layout */
display: flex;
flex-direction: column;
align-items: flex-start;
height: 82px;

/* Gray/00 */
background: #F9F9F9;
border-radius: 16px;

margin-left: auto;
margin-right : auto;
}
.infoRow2 .row1 {

  width : 100%;
  margin-top: 16px;
}
.infoRow2 .row1 .text1{
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.002em;
  
  /* Main/Black */
  color: #0F0F0F;
  
}
.infoRow2 .row1 .icon1{

width: 24px;
height: 24px;
object-fit: contain;
margin-left: 2px;
margin-right : auto;
}
.infoRow2 .row1 .icon2{
  width: 47px;
  height: 27px;
  
  object-fit: contain;
  margin-left: auto;
  margin-right : 0px;
}

.infoRow2 .row2 {
  
  width : 100%;
  margin-top: 12px;
  margin-bottom : 16px;

  margin-left: auto;
  margin-right : auto;
}

.infoRow2 .row2 .text1 {

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */

/* Gray/07 */
color: #666666;

margin-left: 0px;
margin-right : auto;

}


.bottomSheetEmoWrapper .topLabel {

  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  /* or 31px */
  
  /* Gray/Gray-9 */
  color: #212529;
  
  width : calc(100vw - 40px);
  
  min-height: 62px;
  
  margin-top: 78px;
  margin-bottom : 66px;
  margin-left: auto;
  margin-right : auto;
  }


  .small_confirm {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    
    /* Main/Pink */
    background: #FF7A7A;
    border-radius: 100px;

    
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.2px;

/* Main/White */
color: #FFFFFF;

margin-top: 50px;
  }


  .rightFloatUpdateButton {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    width: 61px;
    height: 28px;
    
    /* Gray/01 */
    background: #F5F5F5;
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    
    /* Gray/09 */
    color: #2D2D2D;

    margin-left: auto;
    
  }

  .textArea001 {

    width : 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    display:inline-block; 

    text-align: left; 
    vertical-align: top;
    background-color: transparent;

  }
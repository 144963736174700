
/* width */
.aa01_c  *::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
  
  /* Track */
  .aa01_c  *::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Handle */
  .aa01_c  *::-webkit-scrollbar-thumb {
    display: none;
  }
  
  /* Handle on hover */
  .aa01_c  *::-webkit-scrollbar-thumb:hover {
    display: none;
  }
  

.aa01_c > .top_banner_0 {

    width: 100vw;
    height: calc(100vw * 300 / 375);
    min-height: 375px;
    background: #ECCF80;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

}

.aa01_c  .foldercard0 {
    width: calc(50vw - 32px) !important;
    margin-left: auto;
    margin-right: auto;
}

.aa01_c  .foldercard0:nth-child(2n - 1) {
    margin-left: 20px;
    margin-right: auto;
}


.aa01_c  .cardgrid0 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.aa01_c .card__filterrow1 {
    margin-top: 100px;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(46, 52, 66, 0.05);
}

.aa01_c .folder_filter_div1 {

width: 100px;
height: 40px;
margin-left: 20px;

border-radius: 20px;
font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;
letter-spacing: -0.5px;

background: #181818;
color: #FFFFFF;
}

.folder_filter_div1 > img {
    width: 10px;
    height: 10px;
    object-fit: contain;
    margin-left: 8px;
}
.folder_filter_div2 {
    margin-left: auto;
    width: 100px;
    height: 40px;
    right: 110px;
    top: 128px;
    
    background: #FFFFFF;
    color : #FF7A7A;
    border-radius: 20px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.5px;
}
.folder_filter_div2 > img {
    
width: 8px;
height: 8px;
object-fit: contain;
margin-left: 4px;
}
.folder_filter_div3 {

width: 80px;
height: 40px;
right: 20px;
top: 128px;

background: #FFFFFF;
border-radius: 20px;
border-radius: 20px;
font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;
letter-spacing: -0.5px;

margin-left: 10px;
margin-right: 20px;

}
.folder_filter_div3  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    
}
.folder_filter_div3 .img_wrapper1 {

width: 36px;
height: 36px;
border-radius: 18px;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;

background: #181818;
}
.folder_filter_div3 .img_wrapper2{

width: 36px;
height: 36px;
border-radius: 18px;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;

margin-left: auto;
}

.aa01_c .card__filterrow2 {
    margin-top: 10px;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    

}
.aa01_c .card__filterrow2 .text1 {

font-family: 'Pretendard';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.5px;

color: #212529;
margin-left: 30px;
}
.aa01_c .card__filterrow2 .text2 {

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    
    color: #181818;
    margin-left: 2px;
    margin-right: 2px;
    
}
.aa01_c .card__filterrow2 .checkicon0 {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-left: auto;
}
.aa01_c .card__filterrow2 .text3 {

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    
    color: rgba(33, 37, 41, 0.2);
    
    margin-left: 8px;
    
    margin-right: 30px;
}

.bottom_more_button0 {
    width: calc(100vw - 60px);
    height: 50px;
    
    border: 1px solid rgba(33, 37, 41, 0.2);
    color : #FF7A7A;
    border-radius: 30px;

    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}
.bottom_more_button0 > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.bottom_more_button0 img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-left: 8px;
}


.tab_container_l {
    position: absolute;
    z-index: 77;
    left: 0px;
    /* top: 50%; */
	/* transform : translate ( 0, 50% ); */

    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: 0.5s;
}

.tab_container_l > .tab__item0 {
    width: 45px;
    height: 150px;
    
    background-color: rgba(0, 0, 0, 0.23);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.tab_container_l > .tapGap {
    /* left: 250px !important; */
    margin-left: 100px !important;
}

.tab_container_l > .tab__item0 {
    margin-top: 20px;
}
.tab_container_l >  .tab__item1 {
    margin-top: 0px;
}

.tab_container_l > .tab__item0 img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}



.sidenav {
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: transparent;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  

.side_container_l {
    width: 100%;
    min-height: 600px;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    margin-top: auto;
    margin-bottom: auto;
}
.side_item1 {
    color : rgba(234,88, 53, 1);
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    margin-top: 20px;
}
.side_item2 {
    margin-left: auto;
    margin-right: auto;
}
.side_item2  .container0 {
    margin-left: auto;
    margin-right: auto;
    width: 48.5px;
    height: 48.5px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}

.side_item2  .container0 > .img0 {
    width: 100%;
    height: 100%;
    border-radius: 24.25px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

}
.side_item2  .container0 > .img0   img {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: cover;


}

.profileImg {

}
.profileAddIcon {
    width: 26px;
    height: 26.7px;
    
    position: absolute;
    bottom: -8px;
    right: -8px;
    z-index: 3;
}
.side_item_b {
    width: calc(100% - 40px);
    max-width: 65px;
    height: 1px;
    background-color: rgba( 197 ,193, 180,0.7);
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.side_item2 {
    margin-top: 20px;

}
.side_item3 {
    color : #FF7A7A;
    font-weight: 700;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    margin-top:20px;
}

.side_item4 {
    color : #FF7A7A;
    font-weight: 500;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    margin-top: 40px;
}

.side_item5 {
    color : #FF7A7A;
    font-weight: 500;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    margin-top: 20px;
}

.side_item9 {

    color : rgba(234,88, 53, 1);
    font-weight: 500;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    margin-top: 20px;
}

.side_item8 {
    width: 80px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    /* color : rgba(153, 153, 153, 1); */
    color : #FF7A7A;
    border : 1px solid rgba(153, 153, 153, 1);
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    margin-top: 20px;
}

.side_item6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    /* color : rgba(153, 153, 153, 1); */
    color : #FF7A7A;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    margin-top: 20px;

}

.side_item6 img {
    width: 40px;
    height: 40px;
    object-fit: contain;

    margin-bottom:8px;
}
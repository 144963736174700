.first_screen {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color:  #fff;
}

.first_screen .topLabel {

font-style: normal;
font-weight: 800;
font-size: 22px;
/* or 31px */

/* Gray/Gray-9 */
color: #212529;

width : calc(100vw - 40px);

min-height: 62px;

margin-top: 78px;
margin-bottom : 66px;
margin-left: auto;
margin-right : auto;
}
.first_screen .inputWrapper0 {

    width : calc(100vw - 40px);
    border-bottom : 1px solid #C4C4C4;

    /* width: 177px; */

    height: 28px;
    
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin-left: auto;
    margin-right : auto;

    position : relative;
}
.first_screen .inputWrapper2 {


    margin-top: 34px;
    border-bottom : 1px solid transparent;

}

.first_screen .inputWrapper3 {


    margin-top: 10px;
    border-bottom : 1px solid transparent;

}

.first_screen .inputWrapper0 .emailInput1 {
    
    width : 100%;

font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */

/* Gray/09 */
color: #2D2D2D;


}

.first_screen .inputWrapper0 .inputLabel1 {

    width : calc(100vw - 40px);

font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */

/* Gray/06 */
color: #8B8B8B;
}

.first_screen .inputWrapper0 .inputLabel2 {

    width : calc(100vw - 40px);

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    
    /* Gray/05 */
    color: #A6A6A6;
    
}

.checkButton0 {

    position : absolute;
    right: 0px;
    bottom : 4px;

display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

width: 75px;
height: 28px;

/* Gray/09 */
background: #2D2D2D;
border-radius: 30px;

font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */
text-align: center;
letter-spacing: -0.02em;
text-transform: capitalize;

/* Main/White */
color: #FFFFFF;

}
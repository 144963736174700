.tap01_c > .top_noti_1 {

width: 100vw;
height: calc(100vw * 60px / 375);
min-height: 80px;

display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;

position: relative;

}

.tap01_c > .top_noti_1 > img {

width: 16px;
object-fit: contain;

margin-left: 20px;
}
.tap01_c > .top_noti_1 > .text1 {

    min-height: 80px;
left: 46px;
right: 102px;
top: calc(50% - 12.5px - 0.5px);

font-family: 'Pretendard';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #868E96;
margin-left: 20px;


}


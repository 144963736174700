.top_search_area0 {
width: 100vw;
height: 120px;
background: #FFFFFF;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;


}

.search_btn1 {

width: 100px;
height: 40px;

background: #F8F8F8 !important;
border-radius: 20px;
display: flex;
flex-direction: row;
align-items: center;

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #212529;
    padding-left: 20px;
    margin-top: 10px;
}

.search_btn2 {

    /* width: calc(100vw - 120px); */
    width: calc(100vw - 20px);
height: 40px;

background: #F8F8F8 !important;
border-radius: 20px;

display: flex;
flex-direction: row;
align-items: center;
margin-top: 10px;

}


.search_btn3 {
    width: 100px;
    height: 40px;
    background: #181818;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-left: auto;
    margin-top: 10px;
    
}
.search_btn4 {
    width: 100px;
    height: 40px;
    
    background: #FFFFFF;
    border: 1px solid rgba(33, 37, 41, 0.2);
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    
    color: #212529;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 10px;
}
.search_label0 {
    
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
/* identical to box height, or 188% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #181818;

margin-top: 20px;
margin-left: 20px;
margin-bottom: 5px;

}
.search_row0 {
    width: 100vw;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:  center;

    margin-left: auto;
    margin-right: auto;
}


.search_row0 {
    height: 55px;
    background-color: #fff;
}
.search_row0 .text1 {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    
    margin-left: 20px;
}
.search_row0 .text2 {
    
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.5px;

color: #ADB5BD;

margin-left : auto;
margin-right: 20px;

}
.search_row0 .close1 img {
    width : 10px;
    height: 10px;
    object-fit: contain;

    margin-right: 20px;
}

.search_row0 .buttom_button1 {
    font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 179% */
display: flex;
align-items: center;
letter-spacing: -0.5px;

color: #ADB5BD;
margin-left: 20px;
margin-right: auto;
}

.search_row0 .buttom_button2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height, or 179% */
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.5px;
    
    color: #181818;
    
margin-left: auto;
margin-right: 20px;
}

.chatRoomDetailTopPreviewWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right : auto;

}
.chatRoomDetailTopPreviewWrapper .profileWrapper {

  width: 72px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow : hidden;
  border-radius : 36px;
  margin-left: auto;
  margin-right : auto;
}
.chatRoomDetailTopPreviewWrapper .profImg {
  width : 110%;
  height : 110%;
  object-fit : cover;
}
.chatRoomDetailTopPreviewWrapper .text1 {

  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  /* Main/Black */
  color: #0F0F0F;
  margin-left: auto;
  margin-right : auto;
  margin-top: 14px;


}
.chatRoomDetailTopPreviewWrapper .text2 {

  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */
  text-align: center;
  
  /* Gray/05 */
  color: #A6A6A6;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 14px;
  margin-bottom : 80px;


}
.chatRoomDetailTopPreviewWrapper .buttonBox0 {

  width: 265px;
  height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background: #FFEFEF;
  border-radius: 20px;
  margin-left: auto;
  margin-right : auto;
  margin-top: 14px;

}
.chatRoomDetailTopPreviewWrapper .buttonBox0  .img00 {
  width : 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 6px;
  margin-right : 6px;

}

.chatRoomDetailTopPreviewWrapper .textWrapper {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right : auto;

}
.chatRoomDetailTopPreviewWrapper .text00 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */
  
  /* Gray/09 */
  color: #2D2D2D;
  
}
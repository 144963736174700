.communityFirstRowWrapper0 {

    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.communityFirstRowWrapper0 .row0 {

    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-left: auto;
    margin-right: auto;

}

.communityFirstRowWrapper0 .image0 {

display: flex;
flex-direction: row;
align-items: flex-start;

width: 56px;
height: 56px;

object-fit: contain;
margin-left: 0px;
margin-right: auto;

margin-top: 40px;

}
.communityFirstRowWrapper0 .text1 {

font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 140%;
/* or 25px */

/* Gray/Gray-9 */
color: #212529;

margin-left: 0px;
margin-right: auto;
margin-top: 6px;

}
.communityFirstRowWrapper0 .text2 {

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    display: flex;
    align-items: center;
    
    /* Gray/06 */
    color: #8B8B8B;

    margin-left: 0px;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;

}
.communityFirstRowWrapper0 .button0 {

    width: calc(100% - 40px);

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

height: 48px;

/* Gray/09 */
background: #2D2D2D;
border-radius: 100px;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.2px;

/* Main/White */
color: #FFFFFF;



margin-left: auto;
margin-right: auto;

margin-top: 20px;
margin-bottom: auto;

}



.fullContentView {

    width: 100vw;
    height: 100vh;

display: flex;
flex-direction: column;
justify-content:  flex-start;
align-items: flex-start;

z-index: 999;
background-color: #fff;
}


.fullContentTopWrapper {
    
    width : 100vw;
    /* width : calc(100vw - 40px); */
    min-height: 52px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.fullContentTopTopBar {
    
    width : 100vw;
    height: 52px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    position : relative;
}

.fullContentTopDiv0 {

    width: 48px;
    height: 48px;

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */
display: flex;
align-items: center;
text-align: center;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

/* Gray/08 */
color: #424242;

}
.fullContentTopDiv1 {
    width : 150px;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    
    /* Main/Black */
    color: #0F0F0F;

    margin-top: auto;
    margin-bottom : auto;

    margin-left: auto;
    margin-right : auto;
    
}

.fullContentTopDiv2 {

    width: 48px;
    height: 48px;

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 100%;
/* identical to box height, or 14px */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

/* Gray/08 */
color: #424242;

}

.fullContentTopTopLabel {

    font-family: 'Pretendard';

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */
    
    /* Main/Black */
    color: #0F0F0F;
    

}


.fullContentView .topLabel  {

font-style: normal;
font-weight: 800;
font-size: 22px;
line-height: 140%;
/* or 31px */

/* Gray/Gray-9 */
color: #212529;

width : calc(100vw - 40px);
margin-top: 78px;
margin-left: auto;
margin-right : auto;


}


.inputRow0{


    margin-left: auto;
    margin-right : auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.inputRow0 input {

    width: 100%;
    border : none;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
}

.inputRow0 > .inputWrapper0 {

    border-bottom:  1px solid #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.inputRow0 > .inputWrapper1 {
    
width: 50px;
height: 50px;
    margin-right: 22px;
}
.inputRow0 > .inputWrapper2 {
    width: 50px;
    height: 50px;
    margin-right: 22px;
     
}
.inputRow0 > .inputWrapper3 {
    
    width: 80px;
    height: 50px;
}

.inputRow1 {
    margin-top: 52px;

}


.inputWrapperPostfixLabel {

font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */

/* Gray/09 */
color: #2D2D2D;

margin-right: 22px;


}

.inputWrapperA{

width: calc(100vw - 80px);
height: 50px;
}

.inputWrapperA input {

    text-align: left;
}


.selectJobWrapper {
    width: calc(100% - 40px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

}
.selectJobItem {

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;



}
.selectJobItem .text1 {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 100%;
    /* identical to box height, or 13px */
    text-align: center;
    letter-spacing: -0.002em;
    text-transform: capitalize;
    
    /* Gray/09 */
    color: #2D2D2D;

    }
    .selectJobItem .icon1 {
    width: 24px;
    height: 24px;
    object-fit: contain;    
    margin-left: auto;
}

.contentView .row0 .text1 {

font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 140%;
/* or 25px */

/* Gray/Gray-9 */
color: #212529;

margin-left: 20px;

}
.genderWrapper {
    width : 60px;
    height : 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius : 20px;
    overflow : hidden;
    flex-direction: row;

    background-color: #E2E2E2;

    margin-right : 20px;
}
.genderWrapper .gender1 {


font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */

/* Gray/04 */
color: #C4C4C4;
    width : 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* padding-left: 4px; */

    border-radius : 15px;

}
.genderWrapper .gender2 {
    
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */

/* Gray/04 */
    width : 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

color: #C4C4C4;
border-radius : 15px;

/* padding-right: 4px; */
}


.childrenAddRow1 {
    
    width : 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    
    /* Gray/09 */
    color: #2D2D2D;
    

    margin-top: 40px;



}

.childrenAddRow1 .img0 { 
    margin-right : 10px;

    width: 24px;
    height: 24px;
    object-fit: contain;
}



.title001 {
    
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */

/* Main/Black */
color: #0F0F0F;

display: flex;
flex-direction: row;
align-items: center;

height: 40px;

}